"use client"

import { useEffect } from "react"

import { handleError } from "../utils/errorHandler"

export default function ErrorPage({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    // Log the error to Sentry
    handleError(error)
  }, [error])

  return (
    <div>
      <h2>Something went wrong!</h2>
    </div>
  )
}
