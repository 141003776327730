import { SupernovaError } from "@supernovaio/sdk"

import * as Sentry from "@sentry/nextjs"
import { Context } from "@sentry/types"

export function isSupernovaError(error: unknown): error is SupernovaError {
  // NOTE: for some reasone instanceof SupernovaError is not working
  return (
    error instanceof Error &&
    (error.constructor?.name || "").toLowerCase() === "supernovaerror"
  )
}

export function isBlockedIpError(error: unknown) {
  return (
    error instanceof Error && "code" in error && error.code === "IPRestricted"
  )
}

export function isForbiddenAccessError(error: unknown) {
  return (
    error instanceof Error &&
    (error.message.startsWith("Forbidden") ||
      ("status" in error && error.status === 403))
  )
}

export function isUnauthorizedError(error: unknown) {
  if (error instanceof Error) {
    return (
      ("status" in error && error.status === 401) ||
      ("status" in error &&
        error.status === 404 &&
        [
          "Design System Version does not exist or operation is forbidden",
          "Design System Source does not exist or operation is forbidden",
          "Design System Documentation does not exist or operation is forbidden",
        ].includes(error.message)) ||
      ("code" in error &&
        (error.code === "NoAccess" || error.code === "UnauthorizedException"))
    )
  }

  return false
}

export function is404Error(error: unknown) {
  if (error instanceof Error) {
    return (
      ("status" in error && error.status === 404) ||
      ("code" in error && error.code === "NotFoundException")
    )
  }

  return false
}

export function handleError(error: SupernovaError | Error, extra?: Context) {
  // eslint-disable-next-line no-console
  console.log(error.toString())

  if (extra) {
    // eslint-disable-next-line no-console
    console.log("extra", extra)

    Sentry.withScope((scope) => {
      scope.setContext("extra", extra)
      Sentry.captureException(error)
    })
  } else {
    Sentry.captureException(error)
  }
}
